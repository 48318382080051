import {OKTA_ROLE, SSO_SCHEME_TMF, SSO_SCHEME_NI} from '~/config';
import AnalyticsUtils from '~/core/utils/AnalyticsUtils';

const UserTypeFormats = Object.freeze({
	AUTHBROKER: 'Authenticated Broker',
	AUTHEMPLOYER: 'Authenticated Employer',
	AUTHPOLICYEMPLOYER: 'Authenticated Policy Employer',
	AUTHINJUREDWORKER: 'Authenticated Injured Worker',
	TMFAGENCYUSER: 'TMF Agency User',
	UNAUTH: 'Unauthenticated',
	AUTH: 'Authenticated',
	BROKER: 'Broker',
	EMPLOYER: 'Employer',
	INJUREDWORKER: 'Injured Worker',
});
const LOGIN_SUCCESS_FLAG = 'login-success-flag';

export default class AnalyticsHandler {
	// For storing the details of the currently logged in user.
	// Note that this refreshes whenever the AnalyticsHandler class is referenced.
	static User = null;

	// For storing the paymentPlans retrieved in the Premium page as it is from this point
	// where we can get the premium amount options the user could have chosen from.
	// Note that this refreshes whenever the AnalyticsHandler class is referenced.
	static paymentPlans = null;

	// TRACK USER ID
	static logUserId(user) {
		if (this.User) {
			// If the User object of the AnalyticsHandler class is not null, userID was already set
			return;
		}

		// Exit if not logged-in
		if (!user || !user.crmContactId) {
			return;
		}

		// Pass User ID data to dataLayer
		AnalyticsUtils.callGaTrackingEvent({
			userID: user.crmContactId,
		});

		// User ID is available in dataLayer, now load GA tag
		AnalyticsUtils.initiateLoadOfGATag();

		// Save user info in the AnalyticsHandler's User object
		const isBroker = user.groups && !!user.groups.find(r => r === OKTA_ROLE.POLICY_BROKER);
		AnalyticsHandler.setUser({
			usertype: this.userTypeFormatBrokerEmployer(true, isBroker),
			isBroker: isBroker,
			user: user,
		});
	}

	// TRACKING EVENT: Login Error Messages
	static loginError(userName, oktaErrorMessage) {
		const data = {
			event: 'Login Error Messages',
			loginerrormessage: oktaErrorMessage,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	static getScheme(user) {
		if (!user) {
			return;
		}
		const isCurrentRoleTMF =
			user.groups && !!user.groups.find(r => r === OKTA_ROLE.CLAIMS_TMF_EMPLOYER);
		const isPolicyBroker = user.groups && !!user.groups.find(r => r === OKTA_ROLE.POLICY_BROKER);
		const currentScheme = isCurrentRoleTMF ? SSO_SCHEME_TMF : isPolicyBroker ? 'NA' : SSO_SCHEME_NI;
		return currentScheme;
	}

	static getRole(user) {
		if (!user) {
			return UserTypeFormats.AUTH;
		}
		const isCurrentRoleTMF =
			user.groups && !!user.groups.find(r => r === OKTA_ROLE.CLAIMS_TMF_EMPLOYER);
		if (isCurrentRoleTMF) {
			return UserTypeFormats.TMFAGENCYUSER;
		}
		const isInjuredWorker = user.groups && !!user.groups.find(r => r === OKTA_ROLE.CLAIMS_INJURED);
		if (isInjuredWorker) {
			return UserTypeFormats.AUTHINJUREDWORKER;
		}
		const isEmployer = user.groups && !!user.groups.find(r => r === OKTA_ROLE.CLAIMS_EMPLOYER);
		if (isEmployer) {
			return UserTypeFormats.AUTHEMPLOYER;
		}
		const isPolicyBroker = user.groups && !!user.groups.find(r => r === OKTA_ROLE.POLICY_BROKER);
		if (isPolicyBroker) {
			return UserTypeFormats.AUTHBROKER;
		}
		const isPolicyEmployer =
			user.groups && !!user.groups.find(r => r === OKTA_ROLE.POLICY_EMPLOYER);
		if (isPolicyEmployer) {
			return UserTypeFormats.AUTHPOLICYEMPLOYER;
		}
	}

	// TRACKING EVENT: login_success
	static loginSuccess() {
		const u = AnalyticsHandler.getUser();
		const scheme = this.getScheme(u);
		if (u) {
			const data = {
				event: 'login_success',
				uid: u.user.crmContactId,
				loggedin_usertype: this.getRole(u.user),
				scheme: scheme ? scheme.toUpperCase() : 'NA',
				agencyName: 'NA',
				agencyId: 'NA',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: Accessibility Settings
	static selectAccessibilitySettings(option, font) {
		const data = {
			event: 'Accessibility Settings',
			options: option,
			selectedfont: font,
		};
		AnalyticsUtils.callGaTrackingEvent(data);
	}

	// TRACKING EVENT: End Session Now Inactivity
	static inactivityModalEnd() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'End Session Now Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
			AnalyticsHandler.removeUserLoginSuccessFlag();
		}
	}

	// TRACKING EVENT: fifteen min Inactivity
	static inactivityModal() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'fifteen min Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: Continue Session Inactivity
	static inactivityModalContinue() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'Continue Session Inactivity',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
		}
	}

	// TRACKING EVENT: Inactivity Session Time Out
	static inactivityModalTimeout() {
		const u = AnalyticsHandler.getUser();
		if (u) {
			const data = {
				event: 'Inactivity Session Time Out',
				usertype: u ? u.usertype : '',
			};
			AnalyticsUtils.callGaTrackingEvent(data);
			AnalyticsHandler.removeUserLoginSuccessFlag();
		}
	}

	/**
	 * Helper Methods
	 */

	// Flag that the user has successfully logged in
	static setUserLoginSuccessFlag() {
		const flag = localStorage.getItem(LOGIN_SUCCESS_FLAG);
		const flagParsed = parseInt(flag);
		const flagValue = isNaN(flagParsed) ? 0 : flagParsed;

		// Increment flag and store in localStorage
		localStorage.setItem(LOGIN_SUCCESS_FLAG, flagValue + 1);
	}

	// Clear flag that the user has successfully logged in
	static removeUserLoginSuccessFlag() {
		// Remove item from localStorage
		localStorage.removeItem(LOGIN_SUCCESS_FLAG);
	}

	// Read flag that the user has successfully logged in
	static readUserLoginSuccessFlag() {
		const flag = localStorage.getItem(LOGIN_SUCCESS_FLAG);
		const flagParsed = parseInt(flag);
		const flagValue = isNaN(flagParsed) ? 0 : flagParsed;
		return flagValue;
	}

	// Store current user object
	static setUser(user) {
		this.User = user;
	}

	// Retrieve current user object
	static getUser() {
		return this.User;
	}

	// Get user type (Authenticated Broker or Authenticated Employer or Unauthenticated)
	static userTypeFormat(isAuthenticated, isBroker) {
		if (isAuthenticated) {
			if (isBroker) {
				return UserTypeFormats.AUTHBROKER;
			}
			return UserTypeFormats.AUTHEMPLOYER;
		}
		return UserTypeFormats.UNAUTH;
	}

	// Get user type (Broker or Employer)
	static userTypeFormatBrokerEmployer(isAuthenticated, isBroker) {
		if (isAuthenticated) {
			if (isBroker) {
				return UserTypeFormats.BROKER;
			}
			return UserTypeFormats.EMPLOYER;
		}
		return '';
	}

	static ssoSuccess(useDetails) {
		const analyticsStoredData = localStorage ? localStorage.getItem('ssoRefererData') : false;
		const analyticsParsedData = analyticsStoredData ? JSON.parse(analyticsStoredData) : {};
		const eventDetails = {
			event: 'tmf_ni_portal',
			userID: useDetails.contactId ? useDetails.contactId : '',
			agencyname: analyticsParsedData.agencyName ? analyticsParsedData.agencyName : '',
			agencyId: analyticsParsedData.agencyId ? analyticsParsedData.agencyId : '',
			loggedin_usertype: 'TMF agency user',
			scheme: analyticsParsedData.scheme ? analyticsParsedData.scheme : '',
		};
		AnalyticsUtils.callGaTrackingEvent(eventDetails);
	}
}
