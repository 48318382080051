import React from 'react';
import T from 'prop-types';
import {Redirect, withRouter} from 'react-router-dom';

import Page from '@icare/layouts/lib/Page';
import GlobalAlertBar from '@icare/sitecore-content/lib/GlobalAlertBar';
import MaintenanceService from '@icare/sitecore-content/lib/MaintenanceService';
import Modal from '@icare/components/lib/Modal';
import Footer from '~/core/components/Footer';
import Header from '~/core/components/Header';
import {sitePaths} from '~/paths';
import A11ySkipLinks from '@icare/components/lib/A11ySkipLinks';

@withRouter
class DefaultLayout extends React.Component {
	static propTypes = {
		match: T.object.isRequired,
		children: T.node,
	};

	static defaultProps = {
		children: null,
	};

	constructor(props) {
		super(props);

		this.PORTAL_API_BASE = window.APP_CONFIG.portalApiBaseUrl;

		this.service = new MaintenanceService(this.PORTAL_API_BASE);

		this.state = {
			isMaintenanceModeEnabled: false,
		};
	}

	componentDidMount() {
		Modal.setAppElement(this.page);

		this._isMaintenanceModeEnabled();
	}

	_isMaintenanceModeEnabled = async () => {
		const resp = await this.service.isMaintenanceMode();

		this.setState({isMaintenanceModeEnabled: resp});
	};

	render() {
		if (
			this.state.isMaintenanceModeEnabled &&
			this.props.match.path !== sitePaths.maintenancePage
		) {
			return <Redirect to={sitePaths.maintenancePage} />;
		}

		return (
			<Page
				fetchRef={page => {
					this.page = page;
				}}
			>
				<A11ySkipLinks skipToContentId="#main-content" />
				<Header />
				<main id="main-content">
					<GlobalAlertBar siteApiUrl={this.PORTAL_API_BASE} />
					{this.props.children}
				</main>
				<Footer />
			</Page>
		);
	}
}

export default DefaultLayout;
