import request from 'superagent';
import authBearer from '~/core/utils/authBearer';
import {BASE_URL, BASE_REQ_HEADERS} from '~/config/endpoints';
import {SSO_SCHEME_TMF} from '~/config';
/**
 * Auth Services
 *
 */
export default class AuthServices {
	static async getUserDetails(scheme) {
		const schemeParams = scheme === SSO_SCHEME_TMF ? `?scheme=${scheme.toUpperCase()}` : '';
		const url = `${BASE_URL.user}/v2/portal/workersInsurance/me${schemeParams}`;
		const response = await request.get(url).set(BASE_REQ_HEADERS).use(authBearer);
		return response.body.data;
	}

	static async patchUserDetails(payload) {
		const response = await request
			.patch(`${BASE_URL.user}/v2/portal/workersInsurance/me`)
			.send(payload)
			.set(BASE_REQ_HEADERS)
			.use(authBearer);

		return response.status === 200;
	}
}
