import {observable, action} from 'mobx';

/**
 * Async status
 */
export class AsyncStatus {
	/**
	 * True if network activity in progress
	 * @type {?boolean}
	 */
	@observable isBusy = false;

	/**
	 * True if network activity was succesful
	 * @type {?boolean}
	 */
	@observable isSuccess = false;

	/**
	 * Error of the last network attempt
	 * @type {?object}
	 */
	@observable error = null;

	/**
	 * Sets the isBusy properts
	 * @param {?boolean} value
	 */
	@action
	setBusy(value) {
		this.isBusy = value;
	}

	/**
	 * Sets the isSuccess property
	 * @param {?boolean} value
	 */
	@action
	setSuccess(value) {
		this.isSuccess = value;
	}

	/**
	 * Sets the error object
	 * @param {?object} value
	 */
	@action
	setError(value) {
		this.error = value;
	}
}

export default AsyncStatus;
