const isBannerEnabled = (bannerdata = {}) =>
	(bannerdata && bannerdata.enable && bannerdata.enable !== 'false') || bannerdata.enable === false;

const scheduledStartIsBeforeNow = (bannerdata = {}) => {
	return Date.parse(bannerdata.startDate) <= Date.now();
};
const scheduledEndIsAfterNow = (bannerdata = {}) => {
	return Date.parse(bannerdata.endDate) >= Date.now();
};

export {isBannerEnabled, scheduledStartIsBeforeNow, scheduledEndIsAfterNow};
