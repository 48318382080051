import './asset-path';
/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import {extras} from 'mobx';
import App from './App';
/* eslint-enable */

extras.isolateGlobalState();

ReactDOM.render(<App />, window.document.getElementById('app'));
