import React from 'react';
import T from 'prop-types';

import GELFooter from '@icare/react-kit/lib/components/Footer';
import {sitePaths} from '~/paths';
import {FOOTER_LINKS} from '~/config';
import withAuth from '@icare/auth-okta/lib/withAuth';
import injectContent from '@icare/sitecore-content/lib/injectContent';

@injectContent(['smlFacebook', 'smlYoutube', 'smlInstagram', 'smlLinkedIn'])
@withAuth
export default class Footer extends React.PureComponent {
	static propTypes = {
		navLinks: T.array,
		content: T.object.isRequired,
	};

	static defaultProps = {
		navLinks: [
			{title: 'Contact us', link: FOOTER_LINKS.contactUs, target: '_blank'},
			{title: 'Terms and Condition', link: FOOTER_LINKS.termsAndCondition, target: '_blank'},
			{title: 'Accessibility', link: sitePaths.accessibilityPage, target: ''},
			{title: 'Privacy', link: FOOTER_LINKS.privacy, target: '_blank'},
		],
	};

	render() {
		const {navLinks, content} = this.props;
		const {smlFacebook, smlYoutube, smlInstagram, smlLinkedIn} = content;
		const socialMediaLinks = [
			...(smlLinkedIn.c1
				? [
						{
							href: smlLinkedIn.c1,
							title: 'Like us on linkedin',
							imageSource: require('@icare/core/statics/icons-social/icon-linkedin-colour.svg'),
							imageAlt: 'icare on LinkedIn',
						},
				  ]
				: []),

			...(smlYoutube.c1
				? [
						{
							href: smlYoutube.c1,
							title: 'Like us on youtube',
							imageSource: require('@icare/core/statics/icons-social/icon-youtube-colour.svg'),
							imageAlt: 'icare on Youtube',
						},
				  ]
				: []),

			...(smlFacebook.c1
				? [
						{
							href: smlFacebook.c1,
							title: 'Like us on facebook',
							imageSource: require('@icare/core/statics/icons-social/icon-facebook-colour.svg'),
							imageAlt: 'icare on Facebook',
						},
				  ]
				: []),

			...(smlInstagram.c1
				? [
						{
							href: smlInstagram.c1,
							title: 'Like us on instagram',
							imageSource: require('@icare/core/statics/icons-social/icon-instagram-colour.svg'),
							imageAlt: 'icare on Instagram',
						},
				  ]
				: []),
		];

		return (
			<GELFooter
				logoUrl={require('@icare/core/statics/icons-social/icare-logo-white.svg')}
				navLinks={navLinks}
				socialMediaLinks={socialMediaLinks}
			/>
		);
	}
}
