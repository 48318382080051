import React from 'react';
import T from 'prop-types';

import GELHeader from '@icare/react-kit/lib/components/Header';
import withAuth from '@icare/auth-okta/lib/withAuth';

import {sitePaths, accountPaths, authenticationPaths} from '~/paths';
import {
	MAIN_SITE_ROOT,
	APP_TITLE,
	FAQ_URL,
	FOOTER_LINKS,
	SSO_REFERER_PATH,
	SSO_SCHEME_TMF,
} from '~/config';
import injectContent from '@icare/sitecore-content/lib/injectContent';
import stores from '~/stores';
import Banner from '../Banner/Banner';

@injectContent(['accessibilityLink', 'accountLink', 'contactUsLink', 'faqLink', 'homeLink'])
@withAuth
export default class Header extends React.Component {
	static propTypes = {
		auth: T.object.isRequired,
		content: T.object.isRequired,
	};

	static defaultProps = {};

	_getUserScheme = () => {
		const {authStore} = stores;
		const {user} = authStore;
		if (user && user.getScheme) {
			return user.getScheme;
		} else {
			return '';
		}
	};

	_getLogoutUrl = () => {
		const currentScheme = this._getUserScheme();

		if (currentScheme && currentScheme === SSO_SCHEME_TMF && currentScheme !== '') {
			const referKey = SSO_REFERER_PATH[currentScheme.toLowerCase()];
			const refererLogoutKey = `${referKey}LogoutPath`;
			return SSO_REFERER_PATH[refererLogoutKey];
		} else {
			return authenticationPaths.logout;
		}
	};

	render() {
		const {content} = this.props;
		const {accessibilityLink, accountLink, contactUsLink, faqLink, homeLink} = content;
		const isExternalLogout = this._getUserScheme() === SSO_SCHEME_TMF ? true : false;

		const header = {
			links: [
				...(accessibilityLink.label
					? [
							{
								link: accessibilityLink.url || sitePaths.accessibilityPage,
								label: accessibilityLink.label,
								showAuth: [true, true],
								icon: '',
								mobileBar: false,
								mobileIcon: '',
								target: '',
							},
					  ]
					: []),
				...(faqLink.label
					? [
							{
								link: faqLink.url || FAQ_URL,
								label: faqLink.label,
								showAuth: [false, true],
								icon: '',
								mobileBar: false,
								mobileIcon: '',
								target: '',
							},
					  ]
					: []),
				...(contactUsLink.label
					? [
							{
								link: contactUsLink.url || `${MAIN_SITE_ROOT}${sitePaths.contactUsPage}`,
								label: contactUsLink.label,
								showAuth: [false, true],
								icon: '',
								mobileBar: true,
								mobileIcon: 'contact',
								target: '_blank',
							},
					  ]
					: []),
				...(contactUsLink.label
					? [
							{
								link: FOOTER_LINKS.contactUs,
								label: contactUsLink.label,
								showAuth: [true, false],
								icon: '',
								mobileBar: true,
								mobileIcon: 'contact',
								target: FOOTER_LINKS.contactUs,
							},
					  ]
					: []),
				...(homeLink.label
					? [
							{
								link: homeLink.url || MAIN_SITE_ROOT,
								label: homeLink.label,
								showAuth: [false, true],
								icon: 'home',
								mobileBar: true,
								mobileIcon: 'home',
								target: '',
							},
					  ]
					: []),
				...(accountLink.label
					? [
							{
								link: accountLink.url || `${MAIN_SITE_ROOT}${accountPaths.detailsPage}`,
								label: accountLink.label,
								showAuth: [false, true],
								icon: 'Person',
								mobileBar: false,
								mobileIcon: 'Person',
								target: '',
							},
					  ]
					: []),
			],
			login: {
				showAuth: [false, true],
				loginPath: authenticationPaths.login,
				logoutPath: this._getLogoutUrl(),
			},
		};
		return (
			<div>
				{this.props.auth.isAuthenticated && <Banner />}
				<GELHeader
					header={header}
					homePath={MAIN_SITE_ROOT}
					title={APP_TITLE}
					auth={this.props.auth}
					externalLogout={isExternalLogout}
				/>
			</div>
		);
	}
}
