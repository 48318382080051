import React from 'react';
import FatalErrorPage from '~/pods/site/pages/FatalErrorPage/';

export default class RouteUtils {
	static asyncComponent(getComponent) {
		return class AsyncComponent extends React.Component {
			static Component = null;
			state = {Component: AsyncComponent.Component, hasError: false};

			componentDidCatch(error, info) {
				this.setState({hasError: true, error, info});
			}

			// eslint-disable-next-line camelcase
			UNSAFE_componentWillMount() {
				if (!this.state.Component) {
					getComponent().then(({default: Component}) => {
						AsyncComponent.Component = Component;
						this.setState({Component});
					});
				}
			}

			render() {
				if (this.state.hasError) {
					return <FatalErrorPage error={this.state.error} info={this.state.info} />;
				}
				const {Component} = this.state;
				if (Component) {
					return <Component {...this.props} />;
				}
				return null;
			}
		};
	}
}
