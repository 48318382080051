const stripTrailingSlash = url => {
	if (!url || typeof url !== 'string') {
		return url;
	}
	return url.replace(/\/$/, '');
};

// VALIDATION

const APP_CONFIG = window.APP_CONFIG;
if (!APP_CONFIG) {
	throw new Error('APP_CONFIG is null or undefined');
}

// Required Config Props Sanity Check
[
	'baseApiUrl',
	'contentApiBaseUrl',
	'baseMockUrl',
	'mainSiteRoot',
	'faqUrl',
	'appTitle',
	'policyBaseName',
	'claimsBaseName',
].forEach(required => {
	if (!APP_CONFIG[required]) {
		throw new Error(`APP_CONFIG missing required property "${required}"`);
	}
});

// APP SETTINGS

// Site APIs
export const BASE_API_URL = stripTrailingSlash(APP_CONFIG.baseApiUrl);
export const BASE_MOCK_URL = stripTrailingSlash(APP_CONFIG.baseMockUrl);
export const IDENTITY_API_BASE = stripTrailingSlash(APP_CONFIG.identityApiBaseUrl);
export const REFERENCE_DATA_API_URL = stripTrailingSlash(APP_CONFIG.referenceDataApiBaseUrl);
export const PORTAL_API_BASE = stripTrailingSlash(APP_CONFIG.portalApiBaseUrl);
export const CONTENT_API_BASE = stripTrailingSlash(APP_CONFIG.contentApiBaseUrl);
export const VIRTUAL_PAGE_API_BASE = stripTrailingSlash(APP_CONFIG.virtualPageBaseUrl);
export const MAINTENANCE_MODE_PAGE_API_BASE = stripTrailingSlash(
	APP_CONFIG.maintenanceModeApiBaseUrl,
);
export const PORTAL_LOGOUT_ENDPOINT = APP_CONFIG.logoutEndpoint;
export const API_SANDBOX_MODE = APP_CONFIG.apiSandboxMode === 'true';
export const OKTA_ROLE = {
	POLICY_EMPLOYER: APP_CONFIG.oktaPolicyPortalRoleEmployer,
	POLICY_BROKER: APP_CONFIG.oktaPolicyPortalRoleBroker,
	CLAIMS_EMPLOYER: APP_CONFIG.oktaClaimsPortalRoleEmployer,
	CLAIMS_INJURED: APP_CONFIG.oktaClaimsPortalRoleInjuredWorker,
	CLAIMS_TMF_EMPLOYER: APP_CONFIG.oktaClaimsPortalRoleTMFEmployer,
};
export const G_SITE_ID = APP_CONFIG.googleAppId;

//SSO related configs
export const SSO_ALLOWED_REFERER = APP_CONFIG.allowedSSOReferer;
export const SSO_ALLOWED_SCHEME = APP_CONFIG.allowedSSOScheme;
export const SSO_REFERER_PATH = APP_CONFIG.ssoRefererPaths;
export const SSO_SCHEME_TMF = APP_CONFIG.userSchemeTMF;
export const SSO_SCHEME_NI = APP_CONFIG.userSchemeNI;

// Basenames
export const POLICY_BASE_NAME = stripTrailingSlash(APP_CONFIG.policyBaseName);
export const CLAIMS_BASE_NAME = stripTrailingSlash(APP_CONFIG.claimsBaseName);

// OKTA Config
export const OKTA_CONFIG = {
	url: APP_CONFIG.oktaUrl,
	clientId: APP_CONFIG.oktaClientId,
	issuer: APP_CONFIG.oktaIssuer,
	redirectUri: APP_CONFIG.oktaRedirectUri,
	loggingInUri: APP_CONFIG.oktaLogginInUri,
};
export const OKTA_ENV = APP_CONFIG.oktaEnvironment;

export const INACTIVITY_TIMEOUT = parseInt(APP_CONFIG.inactivityTimeout);
export const INACTIVITY_WARNING = parseInt(APP_CONFIG.inactivityWarning);

// Site Links
export const MAIN_SITE_ROOT = stripTrailingSlash(APP_CONFIG.mainSiteRoot);
export const FAQ_URL = stripTrailingSlash(APP_CONFIG.faqUrl);
export const CONTACT_US_TEL = stripTrailingSlash(APP_CONFIG.contactUsTel);
export const CONTACT_US_URL = stripTrailingSlash(APP_CONFIG.contactUs);
export const APP_TITLE = stripTrailingSlash(APP_CONFIG.appTitle);
export const ICARE_HOMEPAGE = stripTrailingSlash(APP_CONFIG.icareHomePage);
export const SHOW_MANAGING_ENTITY = APP_CONFIG.showManagingEntity === 'true';
export const IFNSW_LOGIN_PAGE = stripTrailingSlash(APP_CONFIG.ifnswLoginRoute);
export const IFNSW_DASHBOARD_PAGE = stripTrailingSlash(APP_CONFIG.ifnswLoginRoute);

export const FOOTER_LINKS = {
	contactUs: APP_CONFIG.contactUs,
	termsAndCondition: APP_CONFIG.termsAndCondition,
	privacy: APP_CONFIG.privacy,
};

// Portal Config
export const PORTAL_CONFIG = APP_CONFIG.portals;

export default null;
