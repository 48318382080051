import React from 'react';
import T from 'prop-types';
import {OKTA_ROLE} from '~/config';

import injectContent from '@icare/sitecore-content/lib/injectContent';
import stores from '~/stores';

import {
	isBannerEnabled,
	scheduledStartIsBeforeNow,
	scheduledEndIsAfterNow,
} from './BannerHelpers/bannerHelpers';

@injectContent(['claimsbanner', 'claimsUserSession'])
class Banner extends React.Component {
	static propTypes = {
		content: T.object.isRequired,
	};
	constructor(props) {
		super(props);
		const {bannerSetting} = props.content.claimsbanner;
		this.state = {displayBanner: this.initBannerState(bannerSetting)};
	}

	initBannerState(bannerSetting) {
		const {claimsSessionToken} = this.props.content.claimsUserSession;
		const Bannersession = localStorage.getItem('Bannersession');
		const isNotInSessionStore =
			Bannersession === null || claimsSessionToken !== Bannersession ? true : false;
		const vaildClaimsuser = this.checkClaimsUser() !== '' ? true : false;
		const displayBanner =
			isBannerEnabled(bannerSetting) &&
			scheduledStartIsBeforeNow(bannerSetting) &&
			scheduledEndIsAfterNow(bannerSetting) &&
			vaildClaimsuser &&
			isNotInSessionStore;
		return displayBanner;
	}

	checkClaimsUser() {
		const {authStore} = stores;
		const {user} = authStore;
		const claimsInjuredWorker = user.roles.find(a => a === OKTA_ROLE.CLAIMS_INJURED);
		const claimsEmployer = user.roles.find(c => c === OKTA_ROLE.CLAIMS_EMPLOYER);
		const claimsTMFEmployer = user.roles.find(c => c === OKTA_ROLE.CLAIMS_TMF_EMPLOYER);

		if (claimsInjuredWorker !== undefined) {
			return 'InjuredWorker';
		} else if (claimsEmployer !== undefined || claimsTMFEmployer !== undefined) {
			return 'Employer';
		} else return '';
	}

	closeBanner = e => {
		e.stopPropagation();
		const {claimsSessionToken} = this.props.content.claimsUserSession;
		this.setState({displayBanner: false});
		localStorage.setItem('Bannersession', claimsSessionToken);
	};

	render() {
		const {claimsbanner} = this.props.content;

		const divStyle = {
			display: !this.state.displayBanner ? 'none' : null,
			backgroundColor:
				claimsbanner.bannerSetting && claimsbanner.bannerSetting.backgroundColor
					? claimsbanner.bannerSetting.backgroundColor
					: '#F5F5F5',
			color:
				claimsbanner.bannerSetting && claimsbanner.bannerSetting.textColor
					? claimsbanner.bannerSetting.textColor
					: '#eeeee4',
			borderColor:
				claimsbanner.bannerSetting && claimsbanner.bannerSetting.borderColor
					? claimsbanner.bannerSetting.borderColor
					: '#00A9E0',
		};

		let BannerMessageData;
		const userType = this.checkClaimsUser();
		if (userType === 'InjuredWorker') {
			BannerMessageData = claimsbanner.claimsWorkerbanner;
		} else if (userType === 'Employer') {
			BannerMessageData = claimsbanner.claimsEmployerbanner;
		}

		return (
			<div className="Banner" style={divStyle}>
				<div className="Banner-content">
					{claimsbanner.bannerSetting ? (
						<div className="Banner-text">
							<img
								className="icon"
								src={claimsbanner.bannerSetting.icon.src}
								alt={claimsbanner.bannerSetting.icon.alt}
							/>
							<div className="alert-message">
								<span dangerouslySetInnerHTML={{__html: BannerMessageData}} />
								<a href={claimsbanner.bannerSetting.link} className="buttondata">
									{claimsbanner.bannerSetting.buttonText}
								</a>
							</div>
						</div>
					) : (
						<div className="Banner-text">
							<div className="alert-message">
								<p>No Banner Content.</p>
							</div>
						</div>
					)}
					<div className="button-wrapper">
						<button
							className="Banner-close"
							data-dismiss="alert"
							type="button"
							tabIndex={this.state.displayBanner ? '0' : '-1'}
							aria-label="Close"
							onClick={this.closeBanner}
						>
							×
						</button>
					</div>
				</div>
			</div>
		);
	}
}
export default Banner;
