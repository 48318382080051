import RouteUtils from '~/core/utils/RouteUtils';
import {authenticationPaths} from '~/paths';

const routes = [
	{
		path: authenticationPaths.changePassword,
		title: 'Change Password',
		isDynamicRouteRequired: false,
		requiresAuth: true,
		requiredRole: [],
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ChangePasswordPage');
		}),
	},
	{
		path: authenticationPaths.forgotPassword,
		title: 'Forgot Password',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ForgotPasswordPage');
		}),
	},
	{
		path: authenticationPaths.login,
		title: 'Login',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: [],
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/LoginPage');
		}),
	},
	{
		path: authenticationPaths.logout,
		title: 'Logout',
		isDynamicRouteRequired: false,
		requiresAuth: true,
		requiredRole: [],
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/LogoutPage');
		}),
	},
	{
		path: authenticationPaths.resetPassword,
		title: 'Reset Password',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ResetPasswordPage');
		}),
	},
	{
		path: authenticationPaths.setPasswordAndSecurity,
		title: 'Create Password and Security Question',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/SetPasswordAndQuestionPage');
		}),
	},
	{
		path: authenticationPaths.registerAccountThankYouPage,
		title: 'Thank you',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/RegisterAccountThankYouPage');
		}),
	},
	{
		path: authenticationPaths.ssoAuth,
		title: 'Login',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/SSOAuthPage');
		}),
	},
];

export default routes;
