import RouteUtils from '~/core/utils/RouteUtils';
import {accountPaths} from '~/paths';

const routes = [
	{
		title: 'Account details',
		path: accountPaths.detailsPage,
		isDynamicRouteRequired: false,
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/AccountDetailsPage'); // eslint-disable-line
		}),
	},
];

export default routes;
