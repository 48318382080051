import React from 'react';
import {useStrict} from 'mobx';
import {Provider} from 'mobx-react';
import {Helmet} from 'react-helmet';
import favicon from '@icare/core/lib/statics/images/favicon.ico';
import faviconMs from '@icare/core/lib/statics/images/metro-tile.png';
import faviconApple from '@icare/core/lib/statics/images/apple-touch-icon.png';
import AuthProvider from '@icare/auth-okta/lib/AuthProvider';
import SecureRoute from '@icare/auth-okta/lib/SecureRoute';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import '@icare/core/lib/utils/typekit';
import AccessibilitySettings from '@icare/react-kit/lib/components/AccessibilitySettings';
import {OKTA_CONFIG, INACTIVITY_TIMEOUT, INACTIVITY_WARNING} from '~/config';
import AnalyticsUtils from '~/core/utils/AnalyticsUtils';
import AnalyticsHandler from '~/core/utils/AnalyticsHandler';

import DefaultLayout from './layouts';
import stores from './stores';
import routes from './routes';
import {sitePaths, authenticationPaths} from './paths';

import './styles/index.scss';

useStrict(true);

class App extends React.Component {
	constructor(props) {
		super(props);
		AccessibilitySettings.setAccessibilityFont();
		AccessibilitySettings.setAccessibilityContrast();
	}

	// Analytics
	passUserIdToGA(user) {
		// CRM Contact ID is used as GA UserID if available
		if (user && user.crmContactId) {
			AnalyticsHandler.logUserId(user);
			return;
		}

		// CRM Contact ID is empty, user is not logged in:
		// 	- Initiate load of GA tag
		//	- Remove user login success flag
		AnalyticsUtils.initiateLoadOfGATag();
		AnalyticsHandler.removeUserLoginSuccessFlag();
	}

	render() {
		return (
			<Provider {...stores}>
				<AuthProvider
					config={OKTA_CONFIG}
					onSessionResume={stores.authStore.updateSession}
					onSessionContinue={AnalyticsHandler.inactivityModalContinue}
					onSessionEnd={AnalyticsHandler.inactivityModalEnd}
					onSessionStart={AnalyticsHandler.inactivityModal}
					onSessionTimeout={AnalyticsHandler.inactivityModalTimeout}
					inactivityTimeout={INACTIVITY_TIMEOUT}
					inactivityWarning={INACTIVITY_WARNING}
					onDidInitilise={this.passUserIdToGA}
				>
					<BrowserRouter>
						<Switch>
							{routes.map(route => {
								const {component: Component, layout, exact, path} = route;
								const Layout = layout || DefaultLayout;
								const R = route.requiresAuth ? SecureRoute : Route;
								const securityProps = route.requiresAuth
									? {
											loginPath: authenticationPaths.login,
											forbiddenUrl: sitePaths.unauthorisedPage,
											requiredRoles: route.requiredRoles || [],
									  }
									: {};

								return (
									<R
										key={path}
										exact={typeof exact === 'boolean' ? exact : true}
										path={path}
										{...securityProps}
										render={props => {
											return (
												<Layout>
													<Helmet>
														<title>{`${route.title || ''} | icare`}</title>
														<meta name="theme-color" content="#FFF" />
														<meta name="msapplication-TileColor" content="#000" />
														<meta name="msapplication-TileImage" content={faviconMs} />
														<link rel="apple-touch-icon-precomposed" href={faviconApple} />
														<link rel="shortcut icon" href={favicon} />
													</Helmet>
													<Component {...props} {...route.props} routes={route.routes} />
												</Layout>
											);
										}}
									/>
								);
							})}
						</Switch>
					</BrowserRouter>
				</AuthProvider>
			</Provider>
		);
	}
}

export default App;
