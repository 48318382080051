import React from 'react';
import T from 'prop-types';
import Spinner from '@icare/components/lib/Spinner';
import Message from '@icare/components/lib/Message';
import ErrorCodesMessagesService, {
	FolderKeys,
	ApiErrorCodes,
	FatalError,
} from '@icare/sitecore-content/lib/ErrorCodesMessagesService';
import {BASE_URL} from '~/config/endpoints';

/**
 * Will hold the entire list of errors
 */
let scErrors = null;

/**
 * For displaying error messages from API service calls
 */
export default class AsyncErrorMessage extends React.Component {
	static propTypes = {
		error: T.object.isRequired, // error response from the API service call
	};

	constructor(props) {
		super(props);
		this.messageService = new ErrorCodesMessagesService(BASE_URL.getErrorMessage);

		this.state = {
			asyncStatus: {
				isBusy: true,
				error: null,
			},
		};
	}

	componentDidMount = async () => {
		// Default error
		let errorMsg = FatalError;

		// Retrieve the error mapping if empty
		if (!scErrors) {
			scErrors = await this.messageService.getAllErrors(FolderKeys.gwErrorsKey);
		}

		if (scErrors.errorMapping.errorCodes && scErrors.errorMapping.errorMessages) {
			// Set default error message from CMS
			errorMsg = this._getErrorMessage(ApiErrorCodes.genericApiError);

			// If the error response have the correct structure, get the error code from the error response
			if (
				this.props.error &&
				this.props.error.response &&
				this.props.error.response.body &&
				this.props.error.response.body.errors &&
				this.props.error.response.body.errors.length > 0
			) {
				// Get the error code from the error response
				const errorCode = this.props.error.response.body.errors[0].code;

				// Retrieve the corresponding error message given the error code
				errorMsg = this._getErrorMessage(errorCode);

				// If error code is not yet in the error mapping list, use the message from the error response 'detail'
				if (!errorMsg) {
					errorMsg = this.props.error.response.body.errors[0].detail;
				}
			}
		}

		this.setState({
			asyncStatus: {
				isBusy: false,
				error: errorMsg,
			},
		});
	};

	_getErrorMessage = targetCode => {
		const targetErrorCode = scErrors.errorMapping.errorCodes.find(c => c.code === targetCode);
		if (targetErrorCode) {
			const targetMessageId = targetErrorCode.messageId;
			const targetErrorMessage = scErrors.errorMapping.errorMessages.find(
				m => m.messageId === targetMessageId,
			);

			if (targetErrorMessage) {
				return {
					title: targetErrorMessage.messageTitle,
					message: targetErrorMessage.message,
				};
			}
		}
	};

	render() {
		const {error, isBusy} = this.state.asyncStatus;
		return (
			<Spinner active={isBusy}>
				{error !== null && (
					<Message type={Message.type.ERROR} title={error.title}>
						{error.message}
					</Message>
				)}
			</Spinner>
		);
	}
}
