import {observable, action} from 'mobx';

/**
 * Analytics Store
 */
export default class AnalyticsStore {
	//Property Region

	/**
	 * URL Location Prev
	 */
	@observable locationPrevious = null;

	/**
	 * URL Location Current
	 */
	@observable locationCurrent = null;

	//Property Region End

	//region Actions

	/**
	 * set setPreviousLocation
	 */
	@action
	setPreviousLocation(value) {
		this.locationPrevious = value || null;
	}

	/**
	 * Set setCurrentLocation
	 */
	@action
	setCurrentLocation(value) {
		this.locationCurrent = value || null;
	}

	/**
	 * clear locations properties
	 */
	@action
	clearLocations() {
		this.locationPrevious = null;
		this.locationCurrent = null;
	}
}
