import {action} from 'mobx';

export class ReferenceData {
	/**
	 * @type {object[]}
	 */
	value = [];

	/**  */
	@action
	setValue(value) {
		this.value = value;
	}
}

export class ReferenceDataFactory {
	static createFromObject(o) {
		if (!o) {
			return null;
		}

		const rd = new ReferenceData();
		rd.setValue(o.value);

		return rd;
	}
}

export default ReferenceData;
