/* @@GENERATOR:STORE:IMPORT */
import AnalyticsStore from '~/core/stores/AnalyticsStore';
import AuthStore from '~/core/stores/AuthStore';
import {ContentStore} from '~/core/stores/ContentStore';
import ReferenceDataStore from '~/core/stores/ReferenceDataStore';

const stores = {
	/* @@GENERATOR:STORE:ASSIGN */
	analyticsStore: new AnalyticsStore(),
	authStore: new AuthStore(),
	contentStore: ContentStore.create(),
	referenceDataStore: new ReferenceDataStore(),
};

export default stores;
