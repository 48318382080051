import {observable, computed, action} from 'mobx';

export const authorisationType = 'PolicyAuthorisation';

class Authorisation {
	/**
	 * generic name for group name or entity name
	 * @type {?string}
	 */
	@observable name = null;
	/**
	 * generic name for broker code or policy number
	 * @type {?string}
	 */
	@observable code = null;

	/**
	 * @type {?string}
	 */
	@observable role = null;

	/**
	 * @type {?string}
	 */
	@observable newRole = null;

	/**
	 * @type {?string}
	 */
	@observable status = null;
	/**
	 * identityId is used for adding new authorisations
	 * @type {?string}
	 */
	@observable identityId = null;
	/**
	 * authorisationRowId is used for updating authorisations
	 * @type {?string}
	 */
	@observable authorisationRowId = null;

	@action
	setName(value) {
		this.name = value;
	}

	@action
	setCode(value) {
		this.code = value;
	}

	@action
	setRole(value) {
		this.role = value;
	}

	@action
	setNewRole(value) {
		this.newRole = value;
	}

	@action
	setStatus(value) {
		this.status = value;
	}

	@action
	setIdentityId(value) {
		this.identityId = value;
	}

	@action
	setAuthorisationRowId(value) {
		this.authorisationRowId = value;
	}

	@computed
	get toServiceLayerAdd() {
		return {
			accessType: this.role,
			policyNumber: this.code,
			status: this.status || 'active',
		};
	}

	@computed
	get toServiceLayerRemoveAuthorisation() {
		return {
			authorisationRowId: this.authorisationRowId,
			status: 'inactive',
		};
	}

	@computed
	get toServiceLayerAddAuthorisation() {
		return {
			policyNumber: this.code,
			identityId: this.identityId,
			assertions: [
				{
					role: `${this.newRole.charAt(0).toUpperCase()}${this.newRole.slice(1)}`,
					scope: ['ALL'],
				},
			],
		};
	}
}

/**
 * The Authorisation Factory
 */
export class AuthorisationFactory {
	static createFromObject(o, identityId) {
		if (!o) {
			return null;
		}

		const a = new Authorisation();

		a.setRole(o.role);
		a.setName(o.employerName);
		a.setCode(o.policyNumber);
		a.setNewRole(o.newRole || null);
		a.setStatus(o.status);

		if (o.authorisationRowId) {
			a.setAuthorisationRowId(o.authorisationRowId);
		}

		if (identityId) {
			a.setIdentityId(identityId);
		}

		return a;
	}
}
export default Authorisation;
