import uuidv4 from 'uuid/v4';
import StickySessionService from '@icare/core/lib/services/StickySessionService';

import {BASE_API_URL, BASE_MOCK_URL, MAIN_SITE_ROOT} from '~/config';

export const IS_MOCK = {
	// Quote Endpoint status
	apiCall: false,

	// User Endpoint status
	user: false,

	// Error Codes Messages Endpoint
	getErrorMessage: false,
};

export const BASE_URL = {
	// Quote Endpoints
	apiCall: IS_MOCK.apiCall ? BASE_MOCK_URL : BASE_API_URL,

	// User Endpoints
	user: IS_MOCK.user ? BASE_MOCK_URL : BASE_API_URL,

	// Error Codes Messages Endpoint
	getErrorMessage: IS_MOCK.getErrorMessage ? BASE_MOCK_URL : MAIN_SITE_ROOT,
};

export const BASE_REQ_HEADERS = Object.freeze({
	'Content-Type': 'application/vnd.api+json',
	'Cache-Control': 'no-cache',
	'X-InitialSystem': 'WIPolicy-Portal',
	'X-TrackingID': `portalRequest-${uuidv4()}`,
	[StickySessionService.sessionHeaderKey]: StickySessionService.getSessionId(),
});
