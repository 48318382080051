import {observable, action, computed} from 'mobx';
import {OKTA_ROLE, SSO_SCHEME_TMF, SSO_SCHEME_NI} from '~/config';
import {AuthorisationFactory, authorisationType} from '~/core/models/submodels/Authorisation';
import {UserCommPrefIn, UserCommPrefOut} from '~/core/enums/CommunicationPreference';

export class User {
	/**
	 * @type {?string}
	 */
	@observable id = null;
	/**
	 * @type {?string}
	 */
	@observable firstName = null;
	/**
	 * @type {?string}
	 */
	@observable lastName = null;
	/**
	 * @type {?string}
	 */
	@observable email = null;
	/**
	 * @type {?boolean}
	 */
	@observable emailVerified = false;
	/**
	 * @type {?string}
	 */
	@observable contactNumber = null;
	/**
	 * @type {?string}
	 */
	@observable phoneType = null;
	/**
	 * @type {?string}
	 */
	@observable communicationPreference = null;
	/**
	 * @type {?boolean}
	 */
	@observable smsNotifications = false;

	/**
	 * @type {?string[]}
	 */
	@observable roles = null;

	/**
	 * @type {Authorisations[]}
	 */
	@observable authorisations = [];

	/**
	 * @type {?string}
	 */
	@observable contactId = null;

	//region Actions

	/**
	 * Set the user id
	 * @param {?string} value
	 */
	@action
	setUserId(value) {
		this.id = value;
	}

	/**
	 * Set the First name
	 * @param {?string} value
	 */
	@action
	setFirstName(value) {
		this.firstName = value;
	}

	/**
	 * Set the Last name
	 * @param {?string} value
	 */
	@action
	setLastName(value) {
		this.lastName = value;
	}

	/**
	 * Set the Email
	 * @param {?string} value
	 */
	@action
	setEmail(value) {
		this.email = value;
	}

	/**
	 * Set the Email Verified
	 * @param {?boolean} value
	 */
	@action
	setEmailVerified(value) {
		this.emailVerified = value;
	}

	/**
	 * Set the Home Number
	 * @param {?string} value
	 */
	@action
	setContactNumber(value) {
		this.contactNumber = value;
	}

	/**
	 * Set the Preferred Contact
	 * @param {?string} value
	 */
	@action
	setPhoneType(value) {
		this.phoneType = value;
	}

	/**
	 * Set the Communication Preference
	 * @param {?string} value
	 */
	@action
	setCommunicationPreference(value) {
		this.communicationPreference = value;
	}

	/**
	 * Set the SMS notifications
	 * @param {?boolean|string} value
	 */
	@action
	setSmsNotifications(value) {
		if (typeof value === 'boolean') {
			this.smsNotifications = value;
		} else if (typeof value === 'string') {
			this.smsNotifications = value.toUpperCase() === 'YES';
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Set the Roles
	 * @param {?string[]} value
	 */
	@action
	setRoles(value) {
		if (!value) {
			this.roles = null;
		} else if (Array.isArray(value)) {
			this.roles = value;
		} else {
			throw new TypeError();
		}
	}

	/**
	 * Set the Authorisations
	 * @param value
	 */
	@action
	setAuthorisations(value) {
		this.authorisations = value;
	}

	/**
	 * Set the contactID
	 * @param {?string} value
	 */
	@action
	setContactId(value) {
		this.contactId = value;
	}

	@computed
	get isPolicyBroker() {
		return this.roles && !!this.roles.find(r => r === OKTA_ROLE.POLICY_BROKER);
	}

	@computed
	get isPolicyEmployer() {
		return this.roles && !!this.roles.find(r => r === OKTA_ROLE.POLICY_EMPLOYER);
	}

	@computed
	get isClaimsInjuredWorker() {
		return this.roles && !!this.roles.find(r => r === OKTA_ROLE.CLAIMS_INJURED);
	}

	@computed
	get isClaimsEmployer() {
		return (
			this.roles &&
			!!this.roles.find(r => r === OKTA_ROLE.CLAIMS_EMPLOYER || r === OKTA_ROLE.CLAIMS_TMF_EMPLOYER)
		);
	}

	@computed
	get getScheme() {
		const isCurrentRoleTMF =
			this.roles && !!this.roles.find(r => r === OKTA_ROLE.CLAIMS_TMF_EMPLOYER);
		return isCurrentRoleTMF ? SSO_SCHEME_TMF : SSO_SCHEME_NI;
	}

	@computed
	get toRawObject() {
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			emailVerified: this.emailVerified,
			contactNumber: this.contactNumber,
			phoneType: this.phoneType,
			communicationPreference: this.communicationPreference,
			smsNotifications: this.smsNotifications,
		};
	}

	get toServiceLayer() {
		const contactPreference = UserCommPrefOut[this.communicationPreference];
		return {
			data: {
				type: 'Contact',
				attributes: {
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					phone: {
						contactNumber: this.contactNumber,
						phoneType: this.phoneType.replace(/^\w/, c => c.toUpperCase()),
					},
					contactPreference: contactPreference,
					agreeToSmsNotification: this.smsNotifications, // TODO waiting on mule to accept this prop
				},
			},
		};
	}

	//end region
}

/**
 * @typedef UserFactoryParams
 * @property {Object} getUserResponse - the response from GET /me.
 * @property {Object} userOkta - User details from Okta.
 * @property {Object} formInputs - User details from form inputs.
 * @property {User} model - Existing user model instance.
 */
export class UserFactory {
	/**
	 * Create User model
	 * @param {UserFactoryParams} params - The possible inputs to build a user object.
	 */
	static createFromObject(params) {
		const {getUserResponse, userOkta, formInputs, model} = params;
		const u = model || new User();

		if (userOkta) {
			u.setFirstName(userOkta.firstName);
			u.setLastName(userOkta.lastName);
			u.setEmail(userOkta.email);
			u.setEmailVerified(userOkta.emailVerified);
			u.setRoles(userOkta.groups);
		}

		// if (brokers) {
		// 	u.setBrokers(brokers);
		// }

		if (getUserResponse) {
			const {contactInfo, authorisations} = getUserResponse.attributes;
			u.setUserId(getUserResponse.id);

			if (contactInfo) {
				if (contactInfo.firstName) {
					u.setFirstName(contactInfo.firstName);
				}
				if (contactInfo.lastName) {
					u.setLastName(contactInfo.lastName);
				}
				if (contactInfo.email) {
					u.setEmail(contactInfo.email);
				}
				if (contactInfo.phone && contactInfo.phone.contactNumber) {
					u.setContactNumber(contactInfo.phone.contactNumber);
				}
				if (contactInfo.phone && contactInfo.phone.phoneType) {
					u.setPhoneType(contactInfo.phone.phoneType.toLowerCase());
				}
				if (contactInfo.contactPreference) {
					const communicationPreference = UserCommPrefIn[contactInfo.contactPreference];
					u.setCommunicationPreference(communicationPreference);
				}
				if (contactInfo.agreeToSmsNotification) {
					u.setSmsNotifications(contactInfo.agreeToSmsNotification);
				}
				if (contactInfo.contactId) {
					u.setContactId(contactInfo.contactId);
				}
			}
			u.setAuthorisations(
				(() => {
					const assertions = [];

					if (authorisations && authorisations.length) {
						authorisations
							.filter(item => item.type === authorisationType)
							.forEach(item => {
								item.assertions.forEach(assertion => {
									const policy = AuthorisationFactory.createFromObject(assertion);
									if (policy) {
										assertions.push(policy);
									}
								});
							});
					}

					return assertions;
				})(),
			);
		}

		if (formInputs) {
			const userInputs = {...formInputs.accountDetails, ...formInputs.meta};
			u.setFirstName(userInputs.firstName);
			u.setLastName(userInputs.lastName);
			u.setEmail(userInputs.email);
			u.setPhoneType(userInputs.phoneType);
			u.setContactNumber(userInputs.contactNumber);
			u.setSmsNotifications(userInputs.smsNotifications);
			u.setCommunicationPreference(userInputs.contactPreference);
		}

		return u;
	}
}

export default User;
