import RouteUtils from '~/core/utils/RouteUtils';
import {sitePaths} from '~/paths';

const routes = [
	{
		path: sitePaths.homePage,
		title: 'Workers Insurance',
		requiresAuth: true,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/DashboardPage');
		}),
	},
	{
		path: sitePaths.accessibilityPage,
		title: 'Accessibility',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/AccessibilityPage');
		}),
	},
	{
		path: sitePaths.fatalErrorPage,
		title: 'Fatal Error',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/FatalErrorPage');
		}),
	},
	{
		path: sitePaths.unauthorisedPage,
		title: 'Unauthorised',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/UnauthorisedPage');
		}),
	},
	{
		path: sitePaths.contactUsPage,
		title: 'Contact icare',
		isDynamicRouteRequired: false,
		requiresAuth: true,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/ContactUsPage');
		}),
	},
	{
		path: sitePaths.maintenancePage,
		title: 'Maintenance',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/MaintenancePage');
		}),
	},
	{
		path: sitePaths.notFoundPage,
		title: 'Page not Found',
		exact: false,
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/NotFoundPage');
		}),
	},
];

if (process.env.NODE_ENV === 'development') {
	routes.unshift({
		path: sitePaths.siteMap,
		title: 'Index',
		isDynamicRouteRequired: false,
		requiresAuth: false,
		requiredRole: false,
		component: RouteUtils.asyncComponent(() => {
			return import('./pages/SiteMap'); // eslint-disable-line
		}),
	});
}

export default routes;
