export const quoteId = ':quoteId';
export const policyId = ':policyId';
export const brokerageId = ':brokerageId';
export const policyTermNumber = ':policyTermNumber';
export const id = ':id';

export const sitePaths = {
	accessibilityPage: '/accessibility-page',
	fatalErrorPage: '/fatal-error',
	unauthorisedPage: '/unauthorised',
	contactUsPage: '/contact-us',
	maintenancePage: '/maintenance',
	homePage: '/',
	notFoundPage: '*',
};

if (process.env.NODE_ENV === 'development') {
	sitePaths.siteMap = '/sitemap';
}

export const accountPaths = {
	detailsPage: '/profile',
};

export const authenticationPaths = {
	changePassword: '/change-password/',
	forgotPassword: '/forgot-password/',
	forgotRegistrationCode: '/forgot-registration-code/',
	login: '/login/',
	logout: '/logout/',
	resetPassword: '/profile/reset-password/',
	setPasswordAndSecurity: '/set-password-and-security-question/',
	registerAccountThankYouPage: '/register-account-thank-you-page/',
	ssoAuth: '/sso-auth/',
};

export const portalPaths = {
	policyPortal: '/policy',
	claimsPortal: '/claim',
	policyUserProfilePage: '/policy/profile',
	claimsUserProfilePage: '/claim/account/account-details/',
};
