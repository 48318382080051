import React from 'react';
import T from 'prop-types';
import {Layout} from '@icare/layouts/lib/index';
import Message from '@icare/components/lib/Message/index';
import ContentHeader from '@icare/components/lib/ContentHeader/index';
import AsyncErrorMessage from '~/core/components/AsyncErrorMessage';

export default class FatalErrorPage extends React.Component {
	static propTypes = {
		error: T.object,
		info: T.object,
	};

	static defaultProps = {
		error: null,
		info: null,
	};

	_renderError() {
		if (!this.props.error) {
			return null;
		}

		const heading = `${this.props.error.status ? this.props.error.status : '400'} Error`;
		const subHeading = 'The application has encountered an error';

		if (this.props.error.response) {
			// Display friendly error message given the error code from API response
			return (
				<React.Fragment>
					<ContentHeader heading={heading} subHeading={subHeading} />
					<AsyncErrorMessage error={this.props.error} />
				</React.Fragment>
			);
		} else {
			// In 'development' environment, display actual error message and component stack trace information
			if (process.env.NODE_ENV === 'development') {
				return (
					<React.Fragment>
						<ContentHeader heading={heading} subHeading={subHeading} />
						<Message type={Message.type.ERROR} title={this.props.error.message}>
							{this.props.error.stack.toString()}
							<br />
							<br />
							{this.props.info && this.props.info.componentStack}
						</Message>
					</React.Fragment>
				);
			}

			return null;
		}
	}

	render() {
		return (
			<Layout type={Layout.Type.ONE_COLUMN}>
				{this._renderError()}
				<hr role="presentation" />
				<p>
					Something went wrong. Please try again and{' '}
					<a href="https://www.icare.nsw.gov.au/contact-us">contact us</a> if the issue continues.
				</p>
				<p>Alternatively, you can:</p>
				<ul>
					<li>
						<a href="https://portal.icare.nsw.gov.au/">Go to the claims portal</a>
					</li>
					<li>
						<a href="https://www.icare.nsw.gov.au/workers-insurance-policy-portal">
							Manage your policy
						</a>
					</li>
					<li>
						<a href="https://www.icare.nsw.gov.au/employers/make-a-claim/notify-us-of-an-injury-or-make-a-claim/">
							Make a claim
						</a>
					</li>
					<li>
						<a href="https://www.icare.nsw.gov.au/">Go to the icare home page</a>
					</li>
				</ul>
			</Layout>
		);
	}
}
